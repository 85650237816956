.about_container {
    background-color: #21486f;
    padding-top: 70px;
    padding-bottom: 70px;
    margin: 0%;
}




.leftside {
    padding-left: 70px;
    padding-top: 20px;
    background-image: url('/public/Images/aboutus-bg.jpg');
    background-size: cover;          
    background-position: center;       
    background-repeat: no-repeat;    
max-width: 450px;
    margin-right: 50px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    
}

.leftside h1{
    margin-bottom: 50px;
    color: #21486f;
    font-weight: 700;
    letter-spacing: 1px;
}

.leftside h3{
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: #ffffff;
    filter: none ; 
    
}
.leftside h3 i {
color: rgb(94, 247, 107);
margin-right: 10px;    
filter: none ; 
}
.leftside h2{
  font-size: 1.4rem;
  margin-top: 20px;
  color: #ffffff;
  font-weight: 600;
  background-color: #21486f;
  border-radius: 100%;
  text-align: center;
  align-content: center;
  width: 100px;
  height: 100px;
  letter-spacing: 2px;
    
}

.rightside h1 {
    text-decoration: underline;
    font-size: 2.2rem;
    color: #ffffff;
    margin-bottom: 20px;
    letter-spacing: 1px;
}
.rightside h2 {

    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 20px;
}
.rightside p {

    font-size: .9rem;
    color: #ffffff;
    margin-bottom: 10px;

        line-height: 2;  }
     
      
     

.rightside p b {

    font-size: 1rem;
    color: #ffffff !important;
    margin-bottom: 10px;
    margin-right: 10px;
}

@media (max-width:780px) {
    
.leftside {
text-align: center;
max-width: 700px;
padding: 0%;
margin: 0%;
}
.about_row {
    flex-direction: column;
}
}