.navbar{
  z-index: 51;
  position: sticky;
  height: 10vh;
  top: 0px;
  background-color: #ffffff !important;
  border-bottom:2px solid #595959;
  

}

.logo {
    font-size: 2.3rem;
    font-weight: 900;
    color: #214970 !important;
    transition: 0.4s;
    font-family: 'Poppins' sans-serif;
    letter-spacing: 1.5px;
}

/* for login / signup operations */

.logincolum {
    text-align: end;
    align-content: center;
  }

.btn {
    border: 2px solid #595959;
    border-radius: 20px;
    padding: 5px 15px;
    cursor: pointer;
    background-color:white;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.5s ease;
    color: #7f7f7f;
    
    
  }
  
  .btn:hover {
    letter-spacing: 3px;
    background-color: hsl(270, 3%, 27%);
    color: hsl(0, 0%, 100%);
    box-shadow: rgb(105, 104, 105) 0px 7px 29px 0px;
  }
  
  .btn:active {
    letter-spacing: 3px;
    background-color:#cccccc;
    color: hsl(0, 0%, 100%);
    box-shadow: #cef8de 0px 0px 0px 0px;
    transform: translateY(10px);
    transition: 100ms;
  }

  .btn-outline-success {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #cccccc;
    border: 2px solid #cccccc;
    background-color: transparent;
    overflow: hidden;
    transition: color 0.4s ease-in-out;
  }
  
.navitems {
    font-size: 17px;
 
    color: #3d3d3d !important;
}
  
.navitems:hover {
    color: #14599e !important;
    
}

.certificatshow {
  text-decoration: underline;
}

@media  (max-width: 992px) {
  .navmobileview {
    background-color: white;
    padding: 25px;
    border-radius: 3px;
    border: 2px solid #595959;
  }

}