

/* Policies.module.css */

.policy_container {
  padding: 40px;

  margin: 40px auto;
  margin-top: 0%;
  /* background: linear-gradient(135deg, #e0eafc, #cfdef3); */


  color: #333;
  font-family: 'Arial', sans-serif;
}

.title {
  font-size: 1.8rem;
  text-align: center;
  color: #21486f;
  margin-bottom: 10px;
}

.date {
  text-align: center;
  font-style: italic;
  color: #555;
  margin-bottom: 2px;
}

.section_title {
  font-size: 1.2rem;
  color: #004085;
  margin-top: 5px;
  border-bottom: 1px solid #21486f;
  padding-bottom: 1px;
}

.text {
  font-size: .8rem;
  line-height: 1.55;
  color: #444;
  margin-bottom: 2px;
  /* list-style: square; */
}


.list li {
  margin-bottom: 2px;
  font-size: 1rem;
  color: #333;
}

.list li::marker {
  color: #007bff;
}

@media (max-width: 768px) {
  .policy_container {
    padding: 20px;
    margin: 20px;
  }

  .title {
    font-size: 2rem;
  }

  .section_title {
    font-size: 1.5rem;
  }

  .text {
    font-size: 0.95rem;
  }
}
