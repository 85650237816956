

/* #595959  #7F7F7F   #A5A5A5    #CCCCCC    #F2F2F2 */


/* General Container Styling */
.container-fluid {

    padding: 20px; /* Padding around the container */
  }
  
  /* Heading Description Styling */
  .headingdiscription {
    text-align: center; /* Center-align the heading text */
    margin-bottom: 30px; /* Space below the heading */
  }
  
  .liveclassesmainheading {
    padding-top: 50px;
    font-size: 30px;
    font-weight: 550;
    color: #595959; /* Dark text color */
    font-family: 'Poppins', sans-serif;
  }
  
  /* Row 1 Styling */
  .row1 {
    display: flex;
    justify-content: center; /* Center the items horizontally */
    margin-bottom: 20px; /* Space below row1 */
  }
  
  
 
  
  .liveclasses {
    margin-right: 10px; /* Space between live classes and upcoming classes */
  }
  
  .upcomingclasses {
    margin-left: 10px; /* Space between upcoming classes and live classes */
  }

  


  .liveclasses,
.upcomingclasses {
    color: #595959; 
    padding: 10px;
    border-radius: 5px;
    text-align: center; 
    font-size: 22px;
    font-weight: 500;
    position: relative; 
}

.active {
    border-bottom: 3px solid #4e5d6c ;
  }
  
  
 
  
  /* Row 2 Styling */
  .row2 {
    display: flex;
    justify-content: space-evenly; /* Distribute space between items */
    align-items: center; /* Center items vertically */
    margin-bottom: 20px; /* Space below row2 */
  }
  
  /* Search Input Styling */
  .search {
    background-color: #ffffff; /* White background for search bar */
    padding: 5px;
    border-radius: 5px;
  }
  
  .input {
    width: 100%; /* Full width of the container */
    padding: 8px 12px; /* Padding inside the input */
    border: 1px solid #ced4da; /* Light border color */
    border-radius: 40px; /* Rounded corners */
    font-size: 16px; /* Font size */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  }
  
  /* Dropdown Styling */
  .categorys {
    background-color: #ffffff; /* White background for dropdown */
    padding: 5px;
    border-radius: 4px;
  }
  
  .dropdown {
    width: 100%; /* Full width of the container */
    padding: 10px 12px; /* Padding inside the dropdown */
    border: 1px solid #ced4da; /* Light border color */
    border-radius: 40px; /* Rounded corners */
    font-size: 17px; /* Font size */
    background-color: #ffffff; /* White background */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  }
  
  /* Enter Button Styling */
  .enterButton {
    width: 100%; /* Full width of the container */
    padding: 1px 0px 1px 0px; 
    border: none; /* Remove default border */
    border-bottom: 3px solid #4e5d6c !important;
    background-color: #a5a5a548;
    border-radius: 40px; /* Rounded corners */
    font-size: 25px; /* Font size */
    color: #2e2e2e; /* White text */
    letter-spacing: 5px; /* Adds 2px space between characters */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth background color transition */
  }
  
  .enterButton:hover {
    background-color: #A5A5A5; /* Darker green on hover/focus */
    outline: none; /* Remove default outline */
  }
  .enterButton:active {
    background-color: #A5A5A5; 
    outline: none; 
    transform: translateY(5px);
  }
  /* Row 3 Styling */
  .row3 {
    margin-top: 20px; /* Space above row3 */
  }
  
  .runningliveclasses {
    background-color: #ffffff; /* White background for running live classes */
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
   
    .liveclasses, .upcomingclasses {
      font-size: 1rem;
      width: 45%;
      margin: 10px 0; /* Space between stacked items */
    }
  
    .row2 {
      flex-direction: column; /* Stack items vertically on small screens */
    }
  
    .search, .categorys, .enterButton {
      width: 100%;
      margin-bottom: 10px; /* Space between vertically stacked items */
    }
  .enter {
    padding: 1%;
  }
  }
  

  .delayedMessage {
    text-align: center;
    padding: 20px;
    background: #fffbcc;
    color: #555;
    font-size: 1.2rem;
    border: 1px solid #ffd700;
    border-radius: 10px;
    margin-top: 20px;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  