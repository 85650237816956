

.signupcancel {
    padding: 10px;
    border: none;
}
.signupbox{
  height: 80vh;
  position: relative;
 
}
.signupmodelbox {
   text-align: center;
   
}
.signupcontent {
    width: 90%;
    color: #595959;
    margin-bottom: 10px;
    text-align: start;
    margin-bottom: 15px;
   
   
}

.signupheading {
  font-size: 2.3rem;
    text-align: center;
    color: #595959;
    font-weight: 700;

}

.signupicons {
    display: flex;
    align-items: center;
    
}

.signupIcons {
    height: 40px;
    border: 1.5px solid #ecedec;
    border-right: none;
     padding: 10px;
border-radius: 10px 0px 0px 10px;
}
  
.signupmodalinputtxt {
  border: 1.5px solid #ecedec;
  border-left: none;
  border-radius: 0px 10px 10px 0px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
    transition: 0.2s ease-in-out;
    font-size: 12px;
    outline: none;
}
  
.signupmodalinputpass {
    border: 1.5px solid #ecedec;
  border-left: none;
  border-right: none;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
    transition: 0.2s ease-in-out;
    font-size: 12px;
    outline: none;

    
 

  }
  

  
  .signupmodalbtn {
    margin: 20px 0 10px 0;
    background-color: #3f3e3e;
    border: none;
    color: #cccccc;
    font-size: 19px;
    font-family: inherit;
    font-weight: 500;
    border-radius: 10px;
    height: 50px;
    width: 90%;
    cursor: pointer;
    margin-bottom: 10px;
    transition: .3s;
  }
  
  .signupmodalbtn:hover {
    background-color: #cccccc;
    color: #3f3e3e;
    font-weight: 700;
    
  }


  .showPasswordBtnsignup {
    background-color: white;
    color: #595959;
    cursor: pointer;
    height: 40px;
    border: 1.5px solid #ecedec;
    border-left: none;
border-radius: 0px 10px 10px 0px;
  }
 







  

  
  


  

  







/* Error message styling */
.error {
  color: rgb(212, 76, 76); /* Change text color to red for visibility */
  font-size: 0.7rem; /* Slightly smaller font size */
  margin-top: 5px; /* Space above the error message */
}





/* Success message styles */
.successMessage {
  color: #28a745; /* Green color for success messages */
  font-size: 14px; /* Font size for success messages */
  margin-bottom: 10px; /* Spacing below the success message */
  text-align: center; /* Center the success message */

}

