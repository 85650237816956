.coursebuycontainer_main {
margin: 0%;
padding: 0%;
  background-color: #21486f;
  /* background-image: url('C:\Users\rhars\OneDrive\Desktop\coding\my-live - 3 final\public\Images\cubes.png'); */


}


.coursesbuycontainer {
  position: relative;
  font-family: 'Poppins' sans-serif;
}

.scrollContainer {

  position: relative;
  width: 90%;
  margin: auto;
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scrollContainer::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
}

.coursesbuyrow {
    display: flex;
    width: max-content;
    margin-top: 20px;
    
    
}
.coursesbuyheading {
  position: relative;
    color: #ffffff;
    font-size: 2.5rem;
    font-family: 'Poppins' sans-serif;
    font-weight: 700;
    text-align: center;
 padding-top: 10px;
}

.coursesbuysubtitle{
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1rem;
  color: #a5a5a5;
}








  /* .arrowContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 10%;
    padding: 20px;
    width: 100%;
    height: 100%;
    z-index: 1;
 
  }
   */
  .leftArrow, .rightArrow {
    background-color:transparent; 
    border: none;
    border-radius: 3px;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    position: absolute;
    z-index: 10
  }
  .leftArrow {
    top: 54%;
  }
  .rightArrow{
    top: 54%;
  right: 0px;
  }
  .leftArrow:hover, .rightArrow:hover {
 
    transform: scale(1.1); /* Slightly Enlarge */
  }
  
  .leftArrow:active, .rightArrow:active {

    transform: scale(1); /* Return to Normal Size */
  }
  
  .arrowicon {
    color: #ffffff; /* White Icon */
    font-size: 40px;
    transition: color 0.3s ease;
  }
  
  .leftArrow:hover .arrowicon, .rightArrow:hover .arrowicon {
    color: #206cb9; /* Light Grey Icon on Hover */
  }
  
 
  @media (max-width: 993px) {
    .coursesbuy {
      width: 300px;
      
    }

    .coursesbuylogo {
      width: 100px;
   
    }
 

    .coursesbuybutton {width: 120px;
      margin-left: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 7px 15px;
      font-size: 1rem;
    
    }
     .coursesdata{
      font-size: 1rem;
      white-space: wrap;
     }
    .nameheading {
      margin-top: 15px;
      font-size: 1.3rem;
    }
 
    
  }

  @media (max-width: 600px) {
   
    .leftArrow {
     display: none;
    }
    .rightArrow{
     display: none;
    }
 
   
  }









.coursesbuyrow {
  gap: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
}







  .coursesbuy {
    border: 1px solid rgb(80, 80, 80);
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    transition: transform 0.3s ease-in-out;
    font-family: 'Poppins' sans-serif;
}

.coursesbuy:hover {
    transform: translateY(-5px);
}

.courseslogo img {
    width: 80px;
    height: 80px;
}

.coursesdetail {
    text-align: center;
    margin-top: 15px;
}

.nameheading {
    font-size: 1.5em;
    color: #205b96;
}

.coursesdata {
    font-size: 1em;
    color: #777;
    margin-bottom: 10px;
}

.price {
    font-size: 1.2em;
    font-weight: bold;
    color: #ff6a00;
    margin-bottom: 5px;
}

.courseduration, .coursetechnologies {
    font-size: 0.9em;
    color: #555;
}

.buttoncontainer {
    margin-top: 15px;
}

.learnmorebuy {
    font-size: 0.85em;
    color: #007bff;
    text-decoration: none;
    margin-right: 15px;
    transition: color 0.3s;
}

.learnmorebuy:hover {
    color: #0056b3;
}

.coursesbuybutton {
    padding: 5px 10px;
    background-color: #214970;
    color: #fff;
    border-radius: 20px;
    text-decoration: none;
    transition: background-color 0.3s;
    align-content: center;
    font-size: .85rem !important;
}

.coursesbuybutton:hover {
    background-color: #295480;
}


.coursesdata {
  display: none;
}

.coursetechnologies {
  display: none;
}