
.landbg {
  background-image: url(../../public/Images/cubes.png);
margin: 0%;
padding: 0%;
}
.landingview {
height: 90vh;
padding: 0%;
margin: 0%;
}
.leftcol {
 text-align: center;
align-content: center;
}
.leftcol img {
 
  margin-top: 50px;
  width: 95%;
  height: 250px;
 }

.typewriter {
  margin-top: 70px;
  margin-left: 100px;
  max-width: 325px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: #363636;
  overflow: hidden; 
  border-right: 0.15em solid #7f7f7f;
  white-space: nowrap;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end 20;
}

/* Typing animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%; 
  }
}

/* Caret blinking */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #7f7f7f;
  }
}


.heading {
  text-align: center;
  font-family: 'Poppins';
font-weight: 900;
  font-size: 60px;
  color: #4d4d4d;
  margin-top: 20px;
  margin-bottom: 25px;
  transition: color 0.3s ease-in-out;
  animation: bounceIn 2s ease-in;

}


.subtitle {
  font-family: 'roboto';
  font-size: 1.2rem;
  color: #205b96;
  margin-bottom: 10px;
  animation: fadeIn 2s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}



.enrollnow {
  padding: 10px 30px;
  margin-top: 30px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background-color:#4e5d6c;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s ease;
  color: white;
 
}

.enrollnow:hover {
  letter-spacing: 3px;
  background-color: hsl(270, 3%, 27%);
  color: hsl(0, 0%, 100%);
  box-shadow: rgb(105, 104, 105) 0px 7px 29px 0px;
}

.enrollnow:active {
  letter-spacing: 3px;
  box-shadow: #cef8de 0px 0px 0px 0px;
  transform: translateY(10px);
  transition: 100ms;
}


.code {
  position: relative;
  top: -15px;
  transform: rotate(0deg);
  font-size: 300px;
  opacity: .1;
  color: #595959;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

@keyframes bounceIn {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  20% { transform: translateY(10px); }
 
}







  @media (max-width: 768px) {
    .typewriter {
      font-size: 1.5rem;
      border-right: none;
      margin-left: 50px;
    }
    .landingview {
      height: 100%;
   
      }
  }






@media (max-width: 500px) {

    .typewriter {
  

font-size: 1.3rem;

    
  }
}

@media (max-width: 400px) {

  .typewriter {


font-size: 1rem;

  
}
}





