.packagescontainer {
    

        /* background-image: url(../../public/Images/cubes.png); */
     
    font-family: 'Poppins', sans-serif;
  }
  
  .packageheading {
    font-size: 2.5rem;
    font-weight: 700;
    color: #4e5d6c;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  
  .packagedescription {
    font-size: 1rem;
    color: #606c80;
    margin-bottom: 30px;
  }

  ul, ol {
    list-style-type: none;
    padding-left: 0; /* Optional: Removes default left padding */
    margin: 0;       /* Optional: Removes default margin */
  }
  
  .packagesrow {
box-sizing: border-box;
  height: 100% !important;
   position: relative;
  
  }

  .packagescol{
  margin-bottom: 20px;
}
 
  .packagebox {
  
    border-right: 1px solid #2d3748;
    border-top: 1px solid #2d3748;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
    height: 100%; 
    text-align: center;
    position: relative;
  }
  
  .packagebox:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.2);
  }
  
  .plans {
    margin-bottom: 20px;
  }
  
  .heading {
    font-size: 1.25rem;
    font-weight: 600;
    color: #205b96;
    margin-bottom: 15px;
  }
  
  .packageprice {
    font-size: 1.8rem;
    font-weight: 700;
    color: #ff5a5f;
  }
  
  .description {
    font-size: .9rem;
    color: #4a5568;
    line-height: 1.5;
    
  }
  
  .feature {
    font-size: 0.8rem;
    font-weight: 500;
    color: #2d3748;
    margin-bottom: 15px;
  }
  
  .packagebutton {
    background-color: #214970;
    color: #fff;
    padding: 7px 20px;
    border-radius: 40px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 2px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center;
    margin-top: 10px;
  }
  
  .packagebutton:hover {
    background-color: #13589c;
  }
