.recentjobcontainer {
  padding: 20px;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
   margin-bottom: 10px;
 }
 
  .recentrowhead {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .recentjobmainheading {
     width: 350px;
    font-size: 2.5rem;
    font-weight: 700;
    color: #214970; /* Dark text color */
    font-family: 'Poppins' sans-serif;
    letter-spacing: 2px;
    display: flex;
  }
  .jobserchbutton {

    width: 100%;
    display: flex;
   
    justify-content: space-evenly;
  }
/* #595959  #7F7F7F   #A5A5A5    #CCCCCC    #F2F2F2 */

/* General Container Styling */
.container {
    margin-top: 20px; /* Space above the container */
  }
  
  /* Row 4 Styling */
  .row4 {
    border: 1px solid #4e5d6c;
    display: flex;
    flex-direction: row; /* Image and content side by side */
    background-color: #f8f9fa; /* Light grey background for the card */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Deep shadow for depth */
    padding: 20px; /* Padding inside the card */
    margin-bottom: 20px; /* Space below the card */
    position: relative; /* Position relative for absolute positioning of the button */
    overflow: hidden; /* Hide overflow for animation */
    /* animation: logoAppear 1s ease-in-out; */
  }
 


@keyframes logoAppear {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
  /* Image Styling */
  .row4 img {
    width: 60%; /* Make image fit the container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Rounded corners for image */
    /* margin-right: 50px; Space between image and content */
    padding-right: 20px;
    object-fit: cover; /* Cover the area with the image */
    transition: transform 0.3s ease; /* Smooth zoom effect */
   
  }

  .colimg{
    text-align: center;
    width: 200px;
    align-content: center;
  }
  
  /* Content Styling */
  .content {
    flex: 1; /* Take up remaining space */
    display: flex;
    flex-direction: column; /* Stack text vertically */
    justify-content: center; /* Center content vertically */
  }
  
  .coursename {
    font-size: 2rem;
    font-weight: 700;
    color: #2c2c2c; /* Dark text color */

  }
  
  .coursetime {
    font-size: 16px;
    color: #595959; /* Slightly lighter text color */
   
  }
  
  .courseduration {
    font-size: 16px;
    color: #2c2c2c; /* Lighter text color */
    
  }
  
  .foot{
    
    display: flex;
    justify-content: space-between;
    
  }

  .footcol2nd{
    align-items: end;
    /* padding-top: 30px; */
    text-align: end;
    align-content: end;
    margin-bottom: 8px;
    margin-right: 10px;
  }

  /* Button Styling */
  .btn {
  
    text-decoration: none;
    width: 150px;
    padding: 8px 40px; /* Padding inside the button */
    align-items: end; 
    
    border: none; /* Remove default border */
    border-radius: 40px; /* Rounded corners */
    font-size: 16px; /* Font size */
    color: #ffffff; /* White text */
    background-color: #214970; /* Green background */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth background color transition */
  }
  
  .btn:hover {
    background-color: #4e5d6c; 
    outline: none; 
  }
  .btn:active {
    background-color: #1978d6; 
    outline: none; 
    transform: translateY(5px);
  }
 
 
  
  /* Hover Effect for Image */
  .row4 img:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  



  /* Responsive Adjustments */

  @media (max-width: 996px) {
    
    .foot {
      flex-direction: column;
    }
    .row4 {
      flex-direction: column; /* Stack items vertically on small screens */
      align-items: center; /* Center-align items */
    }
  
    .row4 img {
      margin-bottom: 20px; /* Space below the image */
    }
  
    .btn {
      position: static; /* Remove absolute positioning for small screens */
      margin-top: 20px; /* Space above the button when stacked */
      width: 100%;
    }
  }


  @media (max-width: 768px) {
    .row4 {
      flex-direction: column; /* Stack items vertically on small screens */
      align-items: center; /* Center-align items */
    }
  
    .row4 img {
      margin-bottom: 20px; /* Space below the image */
    }
  
    .btn {
      position: static; /* Remove absolute positioning for small screens */
      margin-top: 20px; /* Space above the button when stacked */
    }
  }
  
  @media (max-width: 556px) {
.recentjobmainheading {
  width: 450px;
}
.colimg {
  display: none;
}
  }

  @media (max-width: 450px) {
    .recentjobmainheading {
      width: 340px;
    }
      }

















      

.droupdownwithsearch {
  display: flex;
  width: 100%;
  text-align: center;
  gap: 10px;
}

.droupdownmenu {
  position: relative; 
  display: inline-block;
}

.dropdownbutton {
  border: none;
  border-bottom: 3px solid #4e5d6c !important;
  background-color: #a5a5a548;
  color: rgb(34, 34, 34);

  width: 300px;
  padding: 7px 10px;
  margin-top: 0;
  font-size: 18px;
  cursor: pointer;
  border-radius: 40px;
  transition: background-color 0.3s ease;
}

.dropdownbutton:hover {
  background-color: #7f7f7f; }

.droupdowncontent {
  position: absolute;
  top: 100%;
 
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10; 
  width: 100%;
  max-height: 300px; 
  overflow-y: auto; 
  transition: opacity 0.3s ease;
}

.dropdownsearchbar {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box; 
}

.droupdownul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdownitemname {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdownitemname:hover {
  background-color: #f1f1f1; 
}

.dropdownitemname:not(:last-child) {
  border-bottom: 1px solid #eee; 
}  

.dropdownbutton:hover {
background-color: #4e5d6c;

}



.droupdownmenu:hover .droupdowncontent,
.droupdownmenu:focus-within .droupdowncontent {
max-height: 300px;
opacity: 1;
visibility: visible;
}

.dropdownsearchbar {
width: 100%;
padding: 8px;
border: 1px solid #ddd;
border-radius: 5px;
margin-bottom: 10px;
transition: border-color 0.3s ease;
}




.dropdownitemname:hover {
background-color: #f1f1f1;
}

/* Additional styles for smooth icon animations */
.droupdownmenu i {
transition: transform 0.3s ease;
}

.droupdownmenu:hover i {
transform: rotate(180deg);
}


@media (max-width: 780px) {
.dropdownbutton { 
  width: 250px;
}
.droupdownwithsearch {
 margin: 0%;
  gap: 10px;
}

}

@media (max-width: 556px) {

.dropdownbutton { 
  width: 100%;
}
.droupdownwithsearch {
  display: flex;
  flex-direction: column;
 margin: 0%;
  gap: 10px;
}

}







.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.closeModalButton {
  background-color: #f44336;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}







