:root {
  --navbar-bg: #214970;
  /* --navbar-hover-bg: #3194f1b9; */
  --navitem-color: #b8b7b7;
  --navitem-hover-color: white;
  --navitem-underline-color: white;
  --sidebar-color: #ffffff;
  --sidebar-hover-color: white;
  --sidebarheading-color: #595959;
}

.navbar {
  width: 100%;
  z-index: 50;
  position: sticky;
  top: 10vh;
 height: 5vh;
  background-color: var(--navbar-bg);
  align-content: center;
}

.sidebarlink {
  text-align: left;
  padding-left: 30px;
  margin-bottom: 10px;
  color: #21486f;
  border: 1px solid gray;
  border-radius: 10px;
  max-width:  250px;
}

.sidebarlink:hover {
letter-spacing: 1px;
  background-color: #21486f;
  color: white;
}
.sidebarlink:active {

  background-color: #21486fb2;
  color: white;
}
.scrollitemcontainer {
  overflow-x: auto; /* Allows horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping of items */
  list-style: none; /* Removes default list bullets */
  position: relative;
  align-content: center;
  
}

/* For the scrollbar */
.scrollitemcontainer::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

/* Hide scrollbar for Firefox */
.scrollitemcontainer {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollitem {

  align-content: center;
  padding: 0; 
  margin: 0%;
  margin-left: 10px;  
  margin-right: 10px;  
  list-style-type: none;
  display: flex; /* Keep items in a row */
}

/* Optional: If you want to keep a custom scrollbar appearance (remove these if you want them completely hidden) */
.scrollitemcontainer::-webkit-scrollbar-thumb {
  background-color: #888; /* Adjust scrollbar thumb color */
  border-radius: 10px; /* Rounding corners of the scrollbar thumb */
}

.scrollitemcontainer::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

.navitem {
  height: 5vh;
  font-weight: 500;
  position: relative;
  color: var(--navitem-color);
  font-size: 13px;
  margin-right: 20px ;
  padding-left: 5px;
  padding-right: 5px;
  
  transition: color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  color: rgba(248, 248, 248, 0.986);
  white-space: nowrap; /* Prevents wrapping in the horizontal scroll container */
  align-content: center;

}

.navitem:hover {
  color: var(--navitem-hover-color);
  transform: translateY(-5px);
}

.navitem::before, .navitem::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 0;
  bottom: 0;
  transition: width 0.5s ease;
}

.navitem::before {
  left: 50%;
}

.navitem::after {
  right: 50%;
}

.navitem:hover::before,
.navitem:hover::after,
.navitem:focus::before,
.navitem:focus::after {
  width: 50%;
}

.navitem:focus {
  color: white;
  outline: none;
  transform: scale(1);
  box-shadow: 0 0 10px var(--navitem-hover-color);
}

.sidebar {
 
  color: var(--sidebar-color);
  font-size: 20px;
  background-color: transparent;
  padding-left: 2px;
  padding: 0%;
  margin: 0%;
  border: none;
  transition: transform 0.3s ease, color 0.3s ease;
  align-content: center;
}
.OffCanvassshow {
  border-bottom: 1px solid gray !important;
  position: absolute;
  top: 15vh;
  height: 100vh;
  width: 350px !important;


}
.sidebarheading{
  color: #21486f !important;
 
  
}

.sidebar:hover {
  color: var(--sidebar-hover-color);
  transform: rotate(90deg);
}

.sidebarheading {
  color: var(--sidebarheading-color);
  font-weight: 900;
} 

@media (max-width: 700px) {

  .OffCanvassshow {
   
    height: 85vh !important;
 
  }
  
 }
 @media (max-width: 460px) {

  .OffCanvassshow {
    width: 300px !important;
  }
  
 }


 .sidebarlistitems {
  overflow-y: scroll !important; 
  max-height: 100%; 
}


.sidebarlistitems::-webkit-scrollbar {
  width: 1px !important; 
}

.sidebarlistitems::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 2px; 
}

.sidebarlistitems::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa; 
}

.sidebarlistitems::-webkit-scrollbar-track {
  background: #cc1212; 
}

/* Firefox */
.sidebarlistitems {
  scrollbar-width: thin; /* Makes scrollbar smaller */
  scrollbar-color: #cccccc #f1f1f1; /* Thumb and track colors */
}

/* Edge and other browsers */
.sidebarlistitems::-ms-scrollbar {
  width: 2px;
}
