.applicationFormWrapper {
    margin: 0 auto;
    padding: 20px;
  }
  
  .formGroup {
    position: relative;
    margin-top: 30px;
    width: 100%;
  }
  .jobHeader {
    display: flex;
    justify-content: space-between;
  }
  .heading {
    font-size: 1.6rem;
    color: #202020;
  }

  .inputsjobrows 
  {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  .inputField,
  .selectField,
  .textArea {
    width: 100%;
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .floatingLabel {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 14px;
    color: #000000;
    pointer-events: none;
    transition: all 0.2s;
    letter-spacing: 1px;
  }
  
 
  .inputField:focus + .floatingLabel,
.inputField:not(:placeholder-shown) + .floatingLabel,
.selectField:focus + .floatingLabel,
.selectField:not([value=""]) + .floatingLabel,
.textArea:focus + .floatingLabel,
.textArea:not(:placeholder-shown) + .floatingLabel {
    top: -25px;
    left: 10px;
    font-size: 16px;
    color: #21486f;
    font-weight: 600;
}
  .fileLabel {
    display: block;
    cursor: pointer;
    color: #21486f;
  }
  
  .submitButton {
    width: 100%;
    padding: 12px;
    background-color: #21486f;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
  }
  
  .submitButton:hover {
    background-color: #2e67a0;
  }
  
  .submitButton:active {
    background-color: #21486f ;
  }
  

  @media (max-width:450px) {
    .inputsjobrows 
    {
        gap: 0px;
      flex-direction: column;
    }
    .jobHeader {
       flex-direction: column;
      }
  }