.internshiprow {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    padding: 10px;
}
.internshipheading {
    font-size: 2.1rem; /* Larger font size for prominence */
    font-weight: 800; /* Bold text for impact */
    color: #595959; /* Dark grey text color */
    padding: 5px;
    text-align: center;
    position: relative;
    display: inline-block;
    text-transform: uppercase; /* Uppercase text for bold look */
    letter-spacing: 2px; /* Increase letter spacing for impact */
 
}






.liveprojectcard {
    margin-bottom: 20px;
}




.liveprojectcard {
    position: relative;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    z-index: 1;
}

.liveprojectcard::before,
.liveprojectcard::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ff4e50;
    transition: all 0.5s ease;
    z-index: -1;
    opacity: 0.8;
}

.liveprojectcard::before {
    top: -50%;
    left: -50%;
    width: 150%;
    height: 150%;
    clip-path: polygon(0 0, 100% 0, 50% 50%);
    background-color: #4e5d6c;
    transform: rotate(0deg);
}

.liveprojectcard::after {
    bottom: -50%;
    right: -50%;
    width: 150%;
    height: 150%;
    clip-path: polygon(50% 50%, 0 100%, 100% 100%);
    background-color: #ff4e50;
    transform: rotate(0deg);
}

.liveprojectcard:hover::before {
    transform: rotate(45deg);
}

.liveprojectcard:hover::after {
    transform: rotate(-45deg);
}

.liveprojectcard:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.liveprojectcard-content {
    position: relative;
    z-index: 2;
}

.liveprojectcard h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #333;
}

.liveprojectcard p {
    color: #555;
    font-size: 1rem;
    line-height: 1.6;
}





.headerrow {
    display: flex;
    justify-content: space-between; /* Align heading on the left, dropdown on the right */
    align-items: center;
    padding: 20px 0;
    position: relative; /* Ensure correct positioning */
    z-index: 10; /* Ensure the header stays on top */
}


.dropdownContainer {
    z-index: 10;
    position: relative; /* To properly position the dropdown */
}

.droupdown {
    width: 100%; /* Ensure dropdown spans full width */
    font-size: 1rem;
    border: 1px solid #7f7f7f;
    border-radius: 5px;
    color: #595959;
    z-index: 10;
    transition: border-color 0.3s ease-in-out;
}



















.courseslogomain {
    display: flex;
    justify-content: space-evenly;
    
}

  .coursesbuy {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
    background: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 10px;
  }
  .courseslogo {
    
    display: flex;
    justify-content: space-between;
    animation: logoAppear 1s ease-in-out;
}

@keyframes logoAppear {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}


.coursesbuylogo {
    width: 70%;
    height: 22vh;
    transition: transform 0.4s ease-in-out;
}

.coursesbuylogo:hover {
    z-index: 5;
    transform: scale(1);
}

.coursesdetail {
    z-index: 1;
    color: #0056b3;
    /* animation: detailFadeIn 1.5s ease-in-out; */
    opacity: 1;
    margin-left: 5px;
}

@keyframes detailFadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.nameheading {
    margin-top: 4px;
    color: #595959;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}



.coursesdata {
    white-space: normal; /* Ensures text wraps and doesn't stay on one line */
    font-size: .9rem;
    color: #666;
    margin: 10px 0;
}

.learnmorebuy {
    font-size: 1.1rem;
    color: #28a745;
    margin-top: 10px;
    transition: color 0.3s ease;
}

.learnmorebuy:hover {
    color: #17a2b8;
}

.coursesbuybutton {
    background-color: white;
    color: #595959;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: transform 1s ease;
}

.coursesbuybutton:hover {
    color: #595959;
   font-weight: 700;
}
.coursesbuybutton:active {
  
    transform: translateY(5px);
  }



.buttonz {
    padding: 4px;
    border: 2px solid  #595959; ;
     background-color: white;
     border-radius: 3px;
    display: flex;
    justify-content: space-between;
}




/* For small devices (tablets and phones) */
/* @media (max-width: 1400px) {
    .mainheading {
        font-size: 1.8rem;
    }
    
    .ourproject, .liveproject {
        border-right: none;
        margin-bottom: 20px;
    }

    .liveprojectcard {
        margin-left: 0;
    }
    
    .coursesbuymain, .coursesbuy {
        width: 100%;
    }

    .coursesbuyrow {
        flex-direction: column;
        align-items: center;
    }

    .coursesbuylogo {
        width: 80px;
        height: 50px;
    }
} */



@media (max-width: 770px) {
    .mainheading {
        font-size: 1.5rem;
    }

    .coursesbuymain, .coursesbuy {
        width: 100%;
    }

    .coursesbuylogo {
        width: 60px;
        height: 40px;
    }

    .navitem {
        font-size: 12px;
    }

    .buttonz {
        padding: 3px;
    }
} 

/* For extra small devices (phones) */
@media (max-width: 770px) {
    .landingview {
        flex-direction: column; /* Stack left and right sections */
        align-items: center; /* Center items */
    }

    .ourproject, .liveproject {
        width: 100%; /* Make each section full width */
        text-align: center; /* Center-align text */
        border-right: none;
    }

    .liveprojectcard {
        width: 100%;
        margin-left: 0;
    }

    .mainheading {
        font-size: 1.5rem;
    }

    .coursesbuymain, .coursesbuy {
        width: 100%;
    }

    .coursesbuylogo {
        width: 60px;
        height: 40px;
    }

    .navitem {
        font-size: 12px;
    }

    .buttonz {
        padding: 3px;
    }
}





/* For extra small devices (phones) */
@media (max-width: 480px) {
    .landingview {
        flex-direction: column; /* Stack left and right sections */
        align-items: center; /* Center items */
    }

    .ourproject, .liveproject {
        width: 100%; /* Make each section full width */
        text-align: center; /* Center-align text */
        border-right: none;
    }

    .liveprojectcard {
        width: 100%;
        margin-left: 0;
    }

    .mainheading {
        font-size: 1.5rem;
    }

    .coursesbuymain, .coursesbuy {
        width: 100%;
    }

    .coursesbuylogo {
        width: 60px;
        height: 40px;
    }

    .navitem {
        font-size: 12px;
    }

    .buttonz {
        padding: 3px;
    }
}



/* For mobile devices */
@media (max-width: 480px) {
  

    .coursesbuy {
        width: 80%; /* Full width on mobile */
    }

   
    .nameheading {
        font-size: 1.2rem;
    }

    .coursesdata {
        font-size: 0.8rem;
    }

    .learnmorebuy {
        font-size: 0.75rem;
    }

    .buttonz {
        /* flex-direction: column; */
        align-items: stretch;
    }

    .coursesbuybutton {
        font-size: 0.8rem;
        padding: 6px;
        margin-bottom: 8px;
    }
}

@media (max-width: 380px) {
  

    .coursesbuy {
        width: 70%; /* Full width on mobile */
    }

   
    .nameheading {
        font-size: 1rem;
    }

    .coursesdata {
        font-size: 0.7rem;
    }

    .learnmorebuy {
        font-size: 0.75rem;
    }

    .buttonz {
        /* flex-direction: column; */
        align-items: stretch;
    }

    .coursesbuybutton {
        font-size: 0.8rem;
        
        margin-bottom: 5px;
    }
}
















/* .previewImage {
    width: 50vw;
    height: 50vh;
    height: auto;
    position: fixed;
    top: 40%;
    left: 20%;
    z-index: 2000;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  } */


  .previewImage {
    width: 70vh;
    height: auto;
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 1000;
  }









  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    color: black;
    cursor: pointer;
  }
  
  .imageContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .modalImage {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    border: 2px solid #ddd;
    border-radius: 5px;
  }
  
  