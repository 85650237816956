.containercerti {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
    text-align: center;
    /* background-color: #fcfcfc; */
  }
  
  .header {
    font-size: 36px;
    font-weight: bold;
    color: #2c3e50;
    text-decoration: underline;
  }
  
  .subHeader {
    font-size: 18px;
    color: #586263;
    margin-bottom: 40px;
  }
  
  .certificatesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
    margin-top: 30px;
  }
  
  .certificateCard {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .certificateTitle {
    font-size: 24px;
    font-weight: bold;
    color: #21486f;
  }
  
  .certificateDescription {
    font-size: 16px;
    color: #34495e;
    margin-top: 10px;
  }
  
  .certiimg {
    max-width: 800px;
    border: 1PX solid black;

  }


  @media  (max-width: 1002px) {
     
  .certiimg {
    max-width: 600px;
   
  }


  }

  @media  (max-width: 702px) {
     
    .certiimg {
      max-width: 400px;
     
    }
  
  
    }


    
  @media  (max-width: 502px) {
     
    .certiimg {
      max-width: 300px;
     
    }
  
  
    }

    @media  (max-width: 350px) {
     
        .certiimg {
          max-width: 250px;
         
        }
      
      
        }