

.logincancel {
    padding: 10px;
    border: none;
}
.logincontent {
    width: 90%;
    color: #595959;
    margin-bottom: 10px;
    text-align: start;
    margin-bottom: 20px;
   
}

.loginheading {
  font-size: 2.5rem;
    text-align: center;
    color: #595959;
    font-weight: 700;


}

.loginmodalbox {
    text-align: center;
}


.loginmodalinputemail {
  border: 1.5px solid #ecedec;
border-left: none;
border-radius: 0px 10px 10px 0px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 5px;
  transition: 0.2s ease-in-out;
  font-size: 12px;
  outline: none;
 

}
  

  
.loginmodalinputpass {
    border: 1.5px solid #ecedec;
  border-left: none;
  border-right: 10px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
    transition: 0.2s ease-in-out;
    font-size: 12px;
    outline: none;
   

  }
  

  
  .loginmodalbtn {
    margin: 20px 0 20px 0;
    background-color: #3f3e3e;
    border: none;
    color: #cccccc;
    font-size: 19px;
    font-family: inherit;
    font-weight: 500;
    border-radius: 10px;
    height: 50px;
    width: 90%;
    cursor: pointer;
    transition: .3s;
  }
  
  .loginmodalbtn:hover {
    background-color: #cccccc;
    color: #3f3e3e;
    font-weight: 700;
  }
  
  .loginicons {
    display: flex;
    align-items: center;
    
}

.loginIcons {
    height: 40px;
    border: 1.5px solid #ecedec;
    border-right: none;
     padding: 10px;
border-radius: 10px 0px 0px 10px;
}

.showPasswordBtnlogin {
  color: #595959;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  height: 40px;
  border: 1.5px solid #ecedec;
  border-left: none;
border-radius: 0px 10px 10px 0px;
}


.footertextlogin {
  font-size: 13px;
  padding-left: 23px;
  padding-right: 25px;
}
  


  


.message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  color: white; /* Change this based on your theme */
  background-color: #28a745; /* Green for success, change based on theme */
}

.message.error {
  background-color: #dc3545; /* Red for errors */
}






