
 
  
  /* New styles for the fixed badge */
  .fixedBadge {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: #f44336; /* Red background for the badge */
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    z-index: 1; /* Ensures the badge stays on top */
    display: flex;
    align-items: center;
   
  }
  
  .badgeIcon {

    margin-right: 10px;
    font-size: 20px; /* Adjust size as needed */
    animation: blink 1s infinite; /* Add blinking animation */
  }
  
  
  @keyframes blink {
    0% {
      opacity: 1;
   
    }
    50% {
      opacity: 0.5;
      color: #f7ef8a;
    }
    100% {
      opacity: 1;
     
    }
  }
  
  .badgeText {

    font-size: 16px;
    font-weight: bold;
  }
  
  /* Applyforinternship.module.css */

.fixedBadge {
  height: 40px;
  position: fixed;
  bottom: 20px;
  right: 17px;
  background-color: #f00;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 50; /* Ensure badge is above other content */
  transition: bottom 0.3s;
}

.scrolled {
  top: 15vh; /* Adjust based on the height of the navbar */
  
}

.badgeText {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
}

.badgeIcon {
  margin-left: 10px;
}


@media (max-width: 540px) {
  .badgeText {

    font-size: 14px;
   
  }
  .badgeIcon {
    font-size: 16px; /* Adjust size as needed */
  }
  .fixedBadge {
    height: 40px;
    right: 10px;
  }  
  
  
}