.formContainer {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 10px;


}

.heading {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputsjobrows  {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.formGroup {
  position: relative;
  flex: 1;
  margin-top: 10px;
}

.inputField,
.selectField,
.textArea {
  width: 100%;
  padding: 14px 12px;
  border: 1px solid #00000088;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  background-color: transparent;
 
}

.selectField {
  appearance: none;
}

.label {
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 14px;
  color: #000000;
  pointer-events: none;
  transform: translateY(-50%);
  transition: 0.2s;
  background-color: #fff;
  padding: 0 5px;
  letter-spacing: 1px;
}

.inputField:focus + .label,
.inputField:not(:placeholder-shown) + .label,
.selectField:focus + .label,
.selectField:not([value=""]) + .label,
.textArea:focus + .label,
.textArea:not(:placeholder-shown) + .label {
  top: -10px;
  left: 10px;
  font-size: 13px;
  color: #21486f;
  font-weight: 600;

}

.fileLabel {
  font-size: 14px;
  color: #000000;
  margin-bottom: 10px;
}

.fileInput {
  width: 100%;
}

.submitButton {
  padding: 12px;
  font-size: 16px;
  background-color: #21486f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.submitButton:hover {
  background-color:#2a5c8f;
}
@media (max-width:450px) {
  .inputsjobrows 
  {
    flex-direction: column;
  }
}