/* General styles for the document */


.firstcontainer {
 position: relative;
 border-bottom: 1px solid  #4c96db ;
}

/* Left column styles */
.leftcolumn {
  position: sticky;
  top: 15vh;
  height: 85vh;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px; /* Padding for better spacing */
  padding-top: 0%;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  color:  #363636;
  /* background-color: #4c96db; */
}

/* Scrollbar styles for WebKit browsers (Chrome, Safari) */
.leftcolumn::-webkit-scrollbar {
 width: 10px; /* Width of the scrollbar */
  background-color:  rgba(255, 255, 255, 0.856); /* Background color for scrollbar track */
}

.leftcolumn::-webkit-scrollbar-track {
 box-shadow: inset 0 0 10px rgba(248, 248, 248, 0.2); /* Add shadow to the track */
}

.leftcolumn::-webkit-scrollbar-thumb {
  background: #4e5d6c; /* Track background color */
  
  border: 2px solid  rgba(255, 255, 255, 0.856);
  
  transition: 0.3s ease; /* Smooth transition for background color */
}


.stickyheading {
  position: sticky;
  top: 0%;
  background-color: white;
  color: #21486f;
  margin: 0%;
  z-index: 10;
  text-align: center;
  padding: 10px;
}

/* Right column styles */
.rightcolumn {
  color: #000000;

}



/* Hover effect for chapter items */
.chapters {
  position: sticky;
  top: 10px;
  list-style-type: none;
  text-decoration: none!important;
  padding-left: 0px;
}

.active {
  border-top: 1px solid #24415f;
  border-bottom: 1px solid #24415f;
  background-color:  whitesmoke; /* Highlight color on hover */
  color: #24415f; /* Text color on hover */
}

.leftcolumn ul li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.leftcolumn ul li:hover {
  background-color:  whitesmoke; /* Highlight color on hover */
  color: #24415f; /* Text color on hover */
}

/* Chapter content styles */
.chaptercontent {
  padding: 20px;
  font-family: Arial, sans-serif;
  border: 1px solid  rgba(255, 255, 255, 0.856);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  
}

/* Responsive adjustments for chapter content */
@media (max-width: 768px) {
  .chaptercontent {
    padding: 15px; /* Reduced padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .chaptercontent {
    padding: 10px; /* Further reduced padding for very small screens */
  }
}

/* Font sizes and styles */
.leftcolumn {
  color: #000000;
  font-size: 1rem; /* Adjusted font size */
}

.paragraph {
  margin-bottom: 20px;
  font-size: 1rem; /* Better font size for readability */
}

.topic {
  font-size: 1rem; /* Larger font size for topics */
  margin-bottom: 10px;
}

pre {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

code {
  padding: 2px 4px;
  border-radius: 3px;

}
.heading {
  font-size: 2rem; /* Large font size for main headings */
  color: #4e5d6c; /* Dark color for contrast */
  border-bottom: 2px solid #4e5d6c; /* Underline with color */
  padding-bottom: 10px; /* Padding below heading */
  margin-bottom: 30px;
  font-weight: 550;
}

.dropdownmenu {
  position: fixed; /* Fixes the dropdown in place */
  top: 60px; /* Adjust this value based on where you want the dropdown to appear vertically */
  left: 20px; /* Adjust this value based on where you want the dropdown to appear horizontally */
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  z-index: 1000; /* Ensures the dropdown is above other elements */
  background-color: #ebebeb;
  border-top: 1px solid #21486f;
  border-bottom: 1px solid #21486f;
  color: #21486f;
  border-radius: 0%;
}



h2 {
  font-size: 1.2rem;
}


/* Courses.module.css */

/* Add this to your existing CSS file */
@media (min-width: 768px) {
  .droupdownbtn {
    display: none;
  }
  .droupdownbtnbar{
    display: none;
  }

}

@media (max-width: 768px) {
  .leftcolumn {
    display: none;
   
  }
  .droupdownbtn{
    color: #595959;
    font-weight: 600;
    width: 100%;
    color: #f4f4f4;
    background-color: #21486f;
    border-top: 1px solid white;
    border-bottom: 1px solid #595959;
  }

  .dropdownmenu {
    cursor: pointer;
    width: 100%;
  padding: 20px;
    position: fixed; /* Fixes the dropdown in place */
    top: 60px; /* Adjust this value based on where you want the dropdown to appear vertically */
    left: 20px; /* Adjust this value based on where you want the dropdown to appear horizontally */
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: hidden; /* Prevents horizontal scrolling */
    z-index: 1000; /* Ensures the dropdown is above other elements */
  }
  .droupdownbtn:hover {
    color: #595959;
    font-weight: 600;
    width: 100%;
    border-top: 1px solid white;
    border-bottom: 1px solid #595959;
   
  }

} 

.codeoverflow {
  overflow: scroll;
  overflow-x: scroll ;
  width: 100vh;
}
 @media (max-width:1110px
  ) {
  
    .codeoverflow {

      width: 70vh;
    }
 }

 @media (max-width:810px
 ) {
 
   .codeoverflow {

     width: 50vh;
   }
}