.logoTrainContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 80px;
  display: flex;
  align-items: center;
  background-color: transparent;
}

/* The main train container for logos */
.logoTrain {
  display: flex;
  gap: 40px; /* Space between logos */
  animation: scrollTrain 1100s linear infinite;
  animation-play-state: paused; /* Initially paused */
  will-change: transform;
}

.logo {
  width: 150px !important;
  height: 150px !important;
}

/* Animation for the logo train */
@keyframes scrollTrain {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* When scrolling, resume the animation */
.scrolling {
  animation-play-state: running;
}

/* Media Query for Tablets */
@media (max-width: 768px) {
  
  .logoTrainContainer {
   margin-top: 40px;
   margin-bottom: 40px;
  }

}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .logoTrainContainer {
    margin-top: 1px;
    margin-bottom: 40px;
   }
 
  .logo {
    width: 100px !important;
    height: 100px !important;
    gap: 20px;
  }
}
