.liveprojectcategories{
 border-top: 2px solid white;
    background-color: #a5a5a5;
   
}

.landingview {
    width: 100%;
    position: relative;
   margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.ourproject {
    color: #595959;
    text-align: center;
    border-right: 2px solid #a5a5a5;
}

.liveproject {
    color: #595959;
    text-align: center;
}

.liveprojectcard {
    margin-left: 21px;
    width: 100%;
}
.mainheading {
    font-family: 'Poppins', sans-serif; /* Modern, clean font */
    font-size: 2.5rem; /* Adjust the size as needed */
    font-weight: 700;
    text-align: center;
    color: transparent;
    color: #214970;
    /* background: linear-gradient(45deg, #ff6f91, #ff9671, #ffc75f, #f9f871, #00c9a7); */
    background-size: 200% 200%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: gradientText 8s ease infinite;
  }
  
  @keyframes gradientText {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
.coursesbuymain {
    position: relative;
    box-sizing: border-box;
    width: 600px;
    background: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 10px;
    margin-top: 20px;
    animation: logoAppear 1s ease-in-out;

  }

 

.scrollitemcategories {
    align-content: center;
    background-color: #a5a5a5;
    height: 40px;
    overflow-x: auto;
    overflow-y: hidden; /* Hide vertical overflow if present */
    white-space: nowrap;
    list-style: none; /* Removes default list bullets */
  }
  
  .scrollitem {
    padding: 0; 
    margin: 0;  
  list-style-type: none;
  align-content: center;
    display: inline-flex; /* Ensure items stay in a row */
  }
  
  .scrollitemcontainer::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for Firefox */
  .scrollitemcontainer {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    align-content: center;
  }
   
  .navitem {
    position: relative;
    color: white !important;
    font-size: 13px;

padding-right: 20px;
    transition: color 0.3s ease, transform 0.3s ease;
    display: inline-block;
    white-space: nowrap; /* Prevents wrapping in the horizontal scroll container */
  }

  .navbackbtn{
    font-size: 17px;
  }

 

  .scrollContainer {
    align-content: center;
    
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scrollContainer::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
}



.coursesbuyrow {
    display: flex;
    width: max-content;
    margin-top: 20px;
    
}
.coursesbuyheading {
  position: relative;
  top: -40px;
    color: #7f7f7f;
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}



.arrowContainer {
    
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.leftArrow, .rightArrow {
    background-color: white;
    border: none;
    color: #7f7f7f;
    font-size: 30px;
    cursor: pointer;
    margin-right: 50px;
    margin-left: 50px;
  
}

.leftArrow:hover, .rightArrow:hover {
    color: #595959;
}




  
  .coursesbuy {
    position: relative;
    box-sizing: border-box;
    width: 450px;
    align-items: center;
    background: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 10px;
  }
  .courseslogo {
    
    display: flex;
    justify-content: space-between;
    animation: logoAppear 1s ease-in-out;
}

@keyframes logoAppear {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.coursesbuylogo {
    width: 150px;
    height: 80px;
    transition: transform 0.4s ease-in-out;
}

.coursesbuylogo:hover {
    z-index: 5;
    transform: scale(1.2);
}

.coursesdetail {
    z-index: 1;
    color: #0056b3;
    /* animation: detailFadeIn 1.5s ease-in-out; */
    opacity: 1;
    margin-left: 5px;
}

@keyframes detailFadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.nameheading {
    margin-top: 4px;
    color: #595959;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}



.coursesdata {
    white-space: normal; /* Ensures text wraps and doesn't stay on one line */
    font-size: .9rem;
    color: #666;
    margin: 10px 0;
}

.learnmorebuy {
    font-size: 1.1rem;
    color: #28a745;
    margin-top: 10px;
    transition: color 0.3s ease;
}

.learnmorebuy:hover {
    color: #17a2b8;
}

.coursesbuybutton {
    background-color: white;
    color: #595959;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: transform 1s ease;
    text-decoration: none;
    list-style: none;
}

.coursesbuybutton:hover {
    color: #595959;
   font-weight: 700;
}
.coursesbuybutton:active {
  
    transform: translateY(5px);
  }



.buttonz {
    
    padding: 4px;
    border: 2px solid  #595959; ;
     background-color: white;
     border-radius: 3px;
    display: flex;
    justify-content: space-between;
}




/* For small devices (tablets and phones) */
@media (max-width: 1400px) {
    .mainheading {
        font-size: 1.8rem;
    }
    
    .ourproject, .liveproject {
        border-right: none;
        margin-bottom: 20px;
    }

    .liveprojectcard {
        margin-left: 0;
    }
    
    .coursesbuymain, .coursesbuy {
        width: 100%;
    }

    .coursesbuyrow {
        flex-direction: column;
        align-items: center;
    }

    .coursesbuylogo {
        width: 80px;
        height: 50px;
    }
}



@media (max-width: 770px) {
    .mainheading {
        font-size: 1.5rem;
    }

    .coursesbuymain, .coursesbuy {
        width: 100%;
    }

    .coursesbuylogo {
        width: 60px;
        height: 40px;
    }

    .navitem {
        font-size: 12px;
    }

    .buttonz {
        padding: 3px;
    }
} 

/* For extra small devices (phones) */
@media (max-width: 770px) {
    .landingview {
        flex-direction: column; /* Stack left and right sections */
        align-items: center; /* Center items */
    }

    .ourproject, .liveproject {
        width: 100%; /* Make each section full width */
        text-align: center; /* Center-align text */
        border-right: none;
    }

    .liveprojectcard {
        width: 100%;
        margin-left: 0;
    }

    .mainheading {
        font-size: 1.5rem;
    }

    .coursesbuymain, .coursesbuy {
        width: 100%;
    }

    .coursesbuylogo {
        width: 60px;
        height: 40px;
    }

    .navitem {
        font-size: 12px;
    }

    .buttonz {
        padding: 3px;
    }
}





/* For extra small devices (phones) */
@media (max-width: 480px) {
    .landingview {
        flex-direction: column; /* Stack left and right sections */
        align-items: center; /* Center items */
    }

    .ourproject, .liveproject {
        width: 100%; /* Make each section full width */
        text-align: center; /* Center-align text */
        border-right: none;
    }

    .liveprojectcard {
        width: 100%;
        margin-left: 0;
    }

    .mainheading {
        font-size: 1.5rem;
    }

    .coursesbuymain, .coursesbuy {
        width: 100%;
    }

    .coursesbuylogo {
        width: 60px;
        height: 40px;
    }

    .navitem {
        font-size: 12px;
    }

    .buttonz {
        padding: 3px;
    }
}



/* For mobile devices */
@media (max-width: 480px) {
  

    .coursesbuy {
        width: 80%; /* Full width on mobile */
    }

   
    .nameheading {
        font-size: 1.2rem;
    }

    .coursesdata {
        font-size: 0.8rem;
    }

    .learnmorebuy {
        font-size: 0.75rem;
    }

    .buttonz {
        /* flex-direction: column; */
        align-items: stretch;
    }

    .coursesbuybutton {
        font-size: 0.8rem;
        padding: 6px;
        margin-bottom: 8px;
    }
}

@media (max-width: 380px) {
  

    .coursesbuy {
        width: 70%; /* Full width on mobile */
    }

   
    .nameheading {
        font-size: 1rem;
    }

    .coursesdata {
        font-size: 0.7rem;
    }

    .learnmorebuy {
        font-size: 0.75rem;
    }

    .buttonz {
        /* flex-direction: column; */
        align-items: stretch;
    }

    .coursesbuybutton {
        font-size: 0.8rem;
        
        margin-bottom: 5px;
    }
}












  
  
  




  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    color: black;
    cursor: pointer;
  }
  
  .imageContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .modalImage {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    border: 2px solid #ddd;
    border-radius: 5px;
  }
  