.footerhead {
    padding-top: 20px;
    border-bottom: 2px solid #595959;
    z-index: 100;
    
 
    /* background: linear-gradient(to bottom, white, #ebebeb);  */
}


.footercontent {
    border-bottom: 2px solid #595959;
    
    padding-top: 50px;
    padding-bottom: 50px;
 
    
}

.subscribeinput {
    border: 2px solid #3d3d3d;
    border-radius: 3px;
    padding: 5px 20px 5px 20px;
    
    
}

.subtxt {
    color: #595959;
}
/* for subscribe btn */


.subscribebtn {
    padding: 5px 20px 5px 20px;
    box-sizing: border-box;
    background-color: #595959;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    border: 2px solid #595959;
    color: white;
    transition: 0.3s;
  }
  
 
   
  .subscribebtn:hover {
    background-color: transparent;
    box-sizing: border-box;
    /* border: 2px solid #595959; */
    color: #595959;

  }
  

/* footer content  */
.footerbase {
  margin: auto;
padding-bottom: 10px;
    align-content: center;
    background-color: #214970 !important;
}
.footerLinksmain {

    border-bottom: 1px solid gray;
    box-sizing: border-box;
    width: 100%;
margin-bottom: 20px;

}
.footerLinksmain p {
 
    align-content: center;
    text-align: center;
  

}
.footerlink {
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;


  

}

.footerlink:hover {
    color: #7ebeff;


  
    
}


.copywrite {
    text-align: start;
    color: #ffffff;
    align-content: center;
}  

.updates {
    color: #595959;
   
}


/* footer logo  */
.logofooter {
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
    color: #ffffff;
    align-content: center;
    margin-top: 10px;
}




.footer_flex{
  display: flex;
  justify-content: space-between;
 margin: 0%;
 padding: 0%;
 margin-top: 10px;
  align-content: center;
}


@media ( max-width : 600px) {
    .formedia {
         width: 100%;
    }

    
}



/* Footer.module.css */
.followUsContainer {
    text-align: end;
    margin-top: 10px;
  }
  
  .followUsHeading {
    margin: auto;
    font-size: 24px;

    color: #ffffff;
  }
  
  .socialIcons {
    display: flex;
    justify-content: end;
    gap: 20px;
  }
  
  .socialIcon {
    font-size: 36px;
    color: #eeeeee;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .socialIcon:hover {
    color: #007bff; /* Change this color to match your theme */
  }
  
  .socialIcon i {
    transition: transform 0.2s;
  }
  
  .socialIcon:hover i {
    transform: scale(1.1);
  }
  





  @media ( max-width : 992px) {
    .followUsHeading {
        
        font-size: 20px;
       
      }

      
      .socialIcon {
        font-size: 26px;
      
      }
}

@media ( max-width : 600px) {
  .followUsHeading {
        
   text-align: center;
   
  }
  }
  



@media ( max-width : 550px) {
.footerbase {
  flex-direction: column;
  text-align: center;
}
.copywrite {
  text-align: center;
}
.socialIcons {
  justify-content: center;
}
}





.footerbase_mid {
  text-align: center;
  align-content: center;
}







.footerLinksmain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}



.footer_flex {
  display: flex;
  justify-content: space-evenly;
width: 100%;
}

/* Laptop view (single row) */
@media (min-width: 1024px) {
  .footerLinksmain {
      flex-direction: row;
  }

  .footer_flex {
      flex-direction: row;
  }
}

/* Mobile view (single column) */
@media (max-width: 780px) {
 
  .footer_flex {
      flex-direction: column;
      align-items: center;
  }
}


/* Mobile view (single column) */
@media (max-width: 455px) {
  .footerLinksmain {
      flex-direction: column;
      width: 100%;
  }

  .footer_flex {
      flex-direction: column;
      align-items: center;
      width: 100%;
  }
}
