/* Services.module.css */
.services {
    padding: 20px 20px;
    background-color: #21486f;
    color: #fff;
    text-align: left;
    position: relative;
  }

  
  .servicesHeader {

    margin-bottom: 10px;
  }
  
  .servicesHeader h4 {
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 2px;
   text-align: center;
   margin-top: 20px;
  }
  
  .servicesHeader h2 {
    font-size:1.5em;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  
  .servicesHeader h2 span {
    color: #45a2ff;
    font-style: italic;
    letter-spacing: 1px;
    padding: 0px 3px;
  }
  
  .servicesHeader p {
    font-size: 0.85em;
    line-height: 1.6;
    color: #b0bec5;
  }
  
  .servicesList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.serviceItem {
    font-size: 1.2em;
    position: relative;
    padding: 20px 20px;
    /* border-bottom: 1px solid rgb(226, 226, 226); */
    cursor: pointer;
    transition: all 0.3s ease;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 4px solid transparent;
  }
  
  .serviceItemActive,
  .serviceItem:hover {
    color: #45a2ff;
    border-left: 4px solid #45a2ff;
  }
  
  .serviceItem h3 {
    margin: 0;
    flex-grow: 1;
  }
  
  /* Arrow icon styling */
  .arrowIcon {
  
    font-size: 1.2em;
    transition: transform 0.3s ease;
 
  }
  
  /* Rotate arrow on hover or when active */
  .serviceItemActive .arrowIcon,
  .serviceItem:hover .arrowIcon {
    transform: rotate(90deg); /* Rotate to point down */
  }
  
  /* Show description below the title */
  .serviceDescription  {
    /* display: none; */
    font-size: 0.9em;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 11px;
    z-index: 10;
    position: absolute;
    background-color: #21486f;
    width: 600px;
    border: 1px solid gray;
  }
  .serviceDescription p {
    color: white !important;
    padding-top: 10px;
     font-size: 1rem;
  }
  
  .serviceItemActive .serviceDescription {
    display: block;
  }
  
  .servicesimg {
    width: 430px;
    position: absolute;
    right: 170px;
    top: 210px;
    z-index: 1;
  }
  @media (max-width:1200px) {
    .servicesimg {
     right: 50px;
    }
  }

  @media (max-width:1000px) {
    .servicesimg {
     width: 300px;
     top: 300px;
    }
  }

  @media (max-width:776px) {
    .servicesimg {
      display: none;
    }
    .serviceDescription  {
   width: 500px;
    }
  }

  @media (max-width:570px) {
  
    .serviceDescription  {
   width: 400px;
    }
  }

  @media (max-width:450px) {
  
    .serviceDescription  {
   width: 300px;
    }
  }