/* @use postcss-nested;
@import url(https://fonts.googleapis.com/css?family=Dancing+Script:400,700);
font-family: 'Dancing Script'; */

.heading h2 {
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
  padding-top: 40px;
}

.heading h2 span {
    color: #21486f;
    font-size: 2.2rem;
}

.heading p {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.7;
    color: #595959;
    margin: 20px 0 60px;
    padding: 0;
    
}

.formControl {
    padding: 25px;
    font-size: 13px;
    margin-bottom: 10px;
    background: #e6e6e6;
    border: 0;
    border-radius: 10px;
    width: 100%;
}

.btn {
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    background: #21486f;
    color: #ffffff;
    width: 100%;
}

.title h3 {
    font-size: 18px;
    font-weight: 600;
}

.title p {
    font-size: 14px;
    font-weight: 400;
    color: #595959;
    line-height: 1.6;
    margin: 0 0 40px;
}

.content .info {
    margin-top: 30px;
}

.content .info i {
    font-size: 30px;
    padding: 0;
    margin: 0;
    color: #02434b;
   
    text-align: center;
    width: 60px;
}

.content .info h4 {
    font-size: 13px;
    line-height: 1.4;
}

.content .info h4 span {
    font-size: 13px;
    font-weight: 300;
    color: #595959;
}
